import { 
    defineComponent, 
    onMounted, 
    reactive, 
    onUnmounted,
    getCurrentInstance, 
    ComponentInternalInstance,  
} from 'vue'
import { Dialog } from 'vant';
import { SET_AMOUNT_INFO } from '@/utils/constant'
import { refundCancel } from '@/api'
import { useRouter } from 'vue-router'

import './index.less'

declare let WeixinJSBridge: any;

const orderState = new Map([
    [0, '待付款'],
    [1000, '已支付'],
    [2000, '交易取消'],
    [10000, '申请退款'],
    [11000, '已退款'],
]) 

const refundState = new Map([
    [0, '退款中'],
    [100, '处理中'],
    [200, '已退款'],
    [1000, '退款失败'],
    [2000, '取消']
])

const typeStatus = new Map([
    [-1, '邀约面试服务费'],
    [0, '内推奖金(投递成功)'],
    [200, '内推奖金(推荐成功)'],
    [400, '内推奖金(面试通过)'],
    [500, '内推奖金(实际到岗)'],
    
])

export default defineComponent({
    setup() {
        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties

        const router = useRouter()

        const state: any = reactive({
            info: {},
            msg:{
                title: '',
                subTitle: ''
            }
        })

        onMounted(() => {
            init()
        })

        onUnmounted(() => {
            const { id } = state.info
            localStorage.removeItem(`${id}_pay`)
            localStorage.removeItem(SET_AMOUNT_INFO)
        })

        const init = () => {
            let res: any = localStorage.getItem(SET_AMOUNT_INFO)
            if (res) {
                res = JSON.parse(res)
                state.info = { ...res.item, type: res.type }
                status({ ...res.item, type: res.type })
            }
        }

        /**
         * 获取状态
         * @param item 
         */
        const status = (item: any) => {
            if (item.type === 0 && item.status === -1) {
                state.msg.title = '交易';
                state.msg.subTitle =  typeStatus.get(item.status)  //'邀约面试服务费'
            }else if (item.type === 0) {
                state.msg.title = '交易';
                state.msg.subTitle =  typeStatus.get(item.status) //'余额充值'
            } else if (item.type === 1) {
                state.msg.title = '充值';
                state.msg.subTitle = '余额充值'
            } else if (item.type === 2) {
                state.msg.title = '退款';
                state.msg.subTitle = '申请退款'
            }
        }

        const _refundCancel = async(id: number) => {
            const res: any = await refundCancel({data: id})
            proxy.$toast(res.message)
            let timers: any = setTimeout(() => {
                clearTimeout(timers)
                timers = null
                router.push({path: '/amount'})
            }, 1500)
        }

        const onOpenRefundCancel = () => {
            Dialog.confirm({
                message: "确认撤销退款吗？",
                closeOnClickOverlay: true,
            }).then(() => {
                _refundCancel(state.info.id)
                console.log('dayinl')
            }).catch(() => {
                // on cancel
                console.log('cancel')
            });
        }

         /**
          * JSAPI调起微信支付
          * @param data 
          */
         const onBridgeReady = (data: any) => {
            //const { data } = state.data
           console.log(data, '看看这里啊。执行了啊。。。。。。。。')
           WeixinJSBridge.invoke('getBrandWCPayRequest', {
               "appId": data.appId,     //公众号ID，由商户传入     
               "timeStamp": data.timeStamp,     //时间戳，自1970年以来的秒数     
               "nonceStr": data.nonceStr,      //随机串     
               "package": data.packageValue,
               "signType": data.signType,     //微信签名方式：     
               "paySign": data.paySign //微信签名 
           },
           function(res: any) {
               if (res.err_msg == "get_brand_wcpay_request:ok") {
                   // 使用以上方式判断前端返回,微信团队郑重提示：
                   //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                   router.push({path: '/result'})
               }
           });
       }

        //继续付款
        const continuePay = () => {
            const { id } = state.info
            let data:any = localStorage.getItem(`${id}_pay`)
            data = JSON.parse(data)
            console.log(data, '看看值....')
            if (data.appId) {
                if (typeof WeixinJSBridge == "undefined") {
                    const doc: any = document
                    if (doc.addEventListener) {
                        doc.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                    } else if (doc.attachEvent) {
                        doc.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                        doc.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                    }
                } else {
                    console.log('11看看这里啊。执行了啊。。。。。。。。')
                    onBridgeReady(data);
                    console.log('看看这里啊。执行了啊。。。。。。。。')
                }
            }
        }

        const buttonUI = () => {
            const orderstates = state.info.orderState
            if (state.info.type === 2 && state.info.refundStatus === 0) {
                return <button class="rechargeInfo-btn" onClick={onOpenRefundCancel}>撤销退款</button>
            }
            if (orderstates === 0) {
                return <button class="rechargeInfo-btn" onClick={continuePay}>继续付款</button>
            }
        }

        const refundUI = () => {
            return (
                <div class="refund-results-box">
                    <div>
                        <div><span class="item-bar-box">类别：</span><span>申请退款</span></div>
                        <div><span class="item-bar-box">状态：</span><span class={`${state.info.refundStatus === 0 ? 'org' : ''} ${state.info.refundStatus === 1000 ? 'red' : ''} ${state.info.refundStatus === 200 ? 'green' : ''}`}>{refundState.get(state.info.refundStatus)}</span></div>
                        <div><span class="item-bar-box">创建时间：</span><span>{state.info.createTime}</span></div>
                        <div><span class="item-bar-box">流水号：</span><span>{state.info.sn}</span></div>
                        <div><span class="item-bar-box">操作用户：</span><span>{state.info.operatorName}</span></div>
                    </div>
                    <div class="refund-results-header">退款账户：</div>
                    <div>
                        <div><span class="item-bar-box">开户银行：</span><span>{state.info.bank}</span></div>
                        <div><span class="item-bar-box">支行名称：</span><span>{state.info.subbranch}</span></div>
                        <div><span class="item-bar-box">银行账号：</span><span>{state.info.bankAccount}</span></div>
                        <div><span class="item-bar-box">开户人姓名：</span><span>{state.info.ownerName}</span></div>
                        <div><span class="item-bar-box">手机号码：</span><span>{state.info.ownerPhone}</span></div>
                        <div><span class="item-bar-box">留言：</span><span>{state.info.remark}</span></div>
                    </div>
                </div>
            )
        }

        const jiaoyiUI = () => {
            let data: any = localStorage.getItem('SET_USER')
            data = JSON.parse(data)
            return (
                <div>
                    <div>
                        <span class="label-box">类别：</span><span>{state.msg.subTitle}</span>
                    </div>
                    <div>
                        <span class="label-box">收款方：</span><span>{data.user.companyName}</span>
                    </div>
                    <div>
                        <span class="label-box">交易时间：</span><span>{state.info.createTime}</span>
                    </div>
                    <div>
                        <span class="label-box">流水号：</span><span>{state.info.sn}</span>
                    </div>
                    <div>
                        <span class="label-box">操作用户：</span><span>{state.info.operatorName}</span>
                    </div>
                    <div>
                        <span class="label-box">说明：</span><span>{state.info.candidateName}{state.info.jobName}</span>
                    </div>
                    <div><span class="label-box">求职者：</span><span>{state.info.candidateName}</span></div>
                </div>
            )
        }

        const chongzhiUI = () => {
            return (
                <div>
                    <div>
                        <span class="label-box">类别：</span><span>{state.msg.subTitle}</span>
                    </div>
                    <div>
                        <span class="label-box">创建时间：</span><span>{state.info.createTime}</span>
                    </div>
                    <div>
                        <span class="label-box">流水号：</span><span>{state.info.sn}</span>
                    </div>
                    <div>
                        <span class="label-box">操作用户：</span><span>{state.info.operatorName}</span>
                    </div>
                    <div>
                        <span class="label-box">状态：</span><span>{orderState.get(state.info.orderState)}</span>
                    </div>
                </div>
            )
        }

        return () => (
            <div class="personal-page">
                <div class="header-box flex-box justify-content-between plr35">
                    <div>详情</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <div class="rechargeInfo-header-box">
                    <div>{state.msg.title}</div>
                    {
                        state.info.type === 2 ? (
                            <div class="money">
                                - {state.info.price}
                            </div>
                        ) : null
                    }
                    {
                        state.info.type === 0 ? (
                            <div class="money">
                                - {state.info.money}
                            </div>
                        ) : null
                    }
                    {/* <div class="money">
                        + {state.info.price}
                    </div> */}
                </div>
                <div class="rechargeInfo-centent-box">
                   
                   { state.info.type === 0 ? jiaoyiUI() : null }

                   { state.info.type === 1 ? chongzhiUI() : null }
                    
                   { state.info.type === 2 ? refundUI() : null }

                    
                </div>
                
                <div class="rechargeInfo-footer-box">
                   {buttonUI()}
                </div>
            </div>
        )
    }
})